import MD5 from 'crypto-js/md5'

export const useLedgerApi = async (url: string, query: Record<string, string>) => {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  let baseURL = config.CAR_LEDGER_API_BASE_URL
  if (process.client) {
    baseURL = ''
  }

  const key = `${url}?${(new URLSearchParams(query)).toString()}`
  const hashedKey = MD5(key).toString()

  const result = await useFetch(url, {
    key: hashedKey,
    deep: false,
    baseURL,
    query,
    headers: {
      Accept: 'application/json;charset=utf-8',
    },
    getCachedData: (key) => {
      // Check if the data is already cached in the Nuxt payload
      if (nuxtApp.isHydrating && nuxtApp.payload.data[key]) {
        return nuxtApp.payload.data[key]
      }

      // Check if the data is already cached in the static data
      if (nuxtApp.static.data[key]) {
        return nuxtApp.static.data[key]
      }

      return null
      // return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  })

  return result
}
